@import 'variables';

$prefix: $antPrefix + -menu;
$darkClass: $prefix + -dark;

$activeArrowClass: '.#{$prefix}-item:hover > .#{$prefix}-submenu-title > .#{$prefix}-submenu-arrow, .#{$prefix}-item-active > .#{$prefix}-submenu-title > .#{$prefix}-submenu-arrow, .#{$prefix}-submenu-active > .#{$prefix}-submenu-title > .#{$prefix}-submenu-arrow, .#{$prefix}-submenu-open > .#{$prefix}-submenu-title > .#{$prefix}-submenu-arrow, .#{$prefix}-submenu-selected > .#{$prefix}-submenu-title > .#{$prefix}-submenu-arrow, .#{$prefix}-submenu-title:hover > .#{$prefix}-submenu-title > .#{$prefix}-submenu-arrow, .#{$prefix}-item:hover > .#{$prefix}-submenu-title:hover > .#{$prefix}-submenu-arrow, .#{$prefix}-item-active > .#{$prefix}-submenu-title:hover > .#{$prefix}-submenu-arrow, .#{$prefix}-submenu-active > .#{$prefix}-submenu-title:hover > .#{$prefix}-submenu-arrow, .#{$prefix}-submenu-open > .#{$prefix}-submenu-title:hover > .#{$prefix}-submenu-arrow, .#{$prefix}-submenu-selected > .#{$prefix}-submenu-title:hover > .#{$prefix}-submenu-arrow, .#{$prefix}-submenu-title:hover > .#{$prefix}-submenu-title:hover > .#{$prefix}-submenu-arrow';
$activeLinkClass: '.#{$prefix}-item:hover,.#{$prefix}-item-active,.#{$prefix}-submenu-active,.#{$prefix}-submenu-open,.#{$prefix}-submenu-selected,.#{$prefix}-submenu-title:hover';
$menuBorderRight: '.#{$prefix}-vertical .#{$prefix}-item::after, .#{$prefix}-vertical-left .#{$prefix}-item::after, .#{$prefix}-vertical-right .#{$prefix}-item::after, .#{$prefix}-inline .#{$prefix}-item::after';

@mixin selectedItemStyle($prefixClass) {
  &.#{$prefixClass}:not(.#{$prefix}-horizontal) .#{$prefix}-item-selected {
    color: #fff;
    background-color: $primaryColor;
  }
  &.#{$prefixClass} .#{$prefix}-item-selected {
    & > a,
    & > a:hover {
      color: #fff;
    }
  }
}
%baseMenu {
  #{$activeLinkClass} {
    & > a {
      color: $primaryColor;
    }
  }
  color: $textColor;
  background: $componentBackground;
}

%lightMenu {
  @extend %baseMenu;
  .#{$prefix}-item:hover,
  .#{$prefix}-item,
  .#{$prefix}-item-active,
  .#{$prefix}:not(.#{$prefix}-inline) .#{$prefix}-submenu-open,
  .#{$prefix}-submenu-active,
  .#{$prefix}-submenu-open,
  .#{$prefix}-submenu-title:hover {
    color: $primaryColor;
  }
  .#{$prefix}-item:active,
  .#{$prefix}-submenu-title:active {
    background-color: transparent;
  }
}

.#{$prefix}-sub.#{$prefix}-inline > .#{$prefix}-item {
   color: $textColor;
  &.#{$prefix}-item-active {
    color: $primaryColor;
  }
}

.#{$prefix}-inline, .#{$prefix}-vertical {
  border-right: transparent;
}

.#{$prefix}-item a {
  color: $textColor;
}
.#{$prefix}-item a:hover {
  color: $secondaryHover;
}

.#{$prefix} {
  @extend %lightMenu;
  @include selectedItemStyle($prefix);
}

.#{$prefix}-inline > .#{$prefix}-item, .#{$prefix}-inline > .#{$prefix}-submenu > .#{$prefix}-submenu-title {
  width: 238px;
  height: 60px;
  line-height: 60px;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 50px;
  font-size: 15px;
  font-weight: 700;
  margin: 0 0 0 8px;
  display: inline-flex;
  align-items: center;

  @media (min-width: 993px) {
    box-shadow: 2px 4px 6px $boxShadowColor; 
  }
  

  a {
    display: inline-flex;
    align-items: center;
  }

  a, span {
    font-size: 15px;
    font-weight: 700;
  }

  .anticon {
    margin: 0 12px 4px 0;
  }
}

.#{$prefix}-inline-collapsed > .#{$prefix}-item .anticon, .#{$prefix}-inline-collapsed > .#{$prefix}-item-group > .#{$prefix}-item-group-list > .#{$prefix}-item .anticon, .#{$prefix}-inline-collapsed > .#{$prefix}-item-group > .#{$prefix}-item-group-list > .#{$prefix}-submenu > .#{$prefix}-submenu-title .anticon, .#{$prefix}-inline-collapsed > .#{$prefix}-submenu > .#{$prefix}-submenu-title .anticon
{
  @media (max-width: 992px) {
    margin: 0;
  }
}

.#{$prefix}-inline > .#{$prefix}-item:not(:last-child), .#{$prefix} > .#{$prefix}-submenu, .#{$prefix} > .#{$prefix}-submenu, .#{$prefix} > .#{$prefix}-submenu {
  margin-bottom: 16px;

  @media (max-width: 992px) {
    margin-bottom: 0;
  }
}

.#{$prefix}-inline .#{$prefix}-item-selected {
  &::after {
    border: none;
  }
}

.#{$prefix}-submenu-selected,
.#{$prefix}-vertical .#{$prefix}-submenu-selected, .#{$prefix}-vertical-left .#{$prefix}-submenu-selected, .#{$prefix}-vertical-right .#{$prefix}-submenu-selected
{
  color: $textColor;
}

.#{$prefix}-vertical > .#{$prefix}-item, .#{$prefix}-vertical-left > .#{$prefix}-item, .#{$prefix}-vertical-right > .#{$prefix}-item, .#{$prefix}-inline-collapsed > .#{$prefix}-item, .#{$prefix}-vertical > .#{$prefix}-submenu > .#{$prefix}-submenu-title, .#{$prefix}-vertical-left > .#{$prefix}-submenu > .#{$prefix}-submenu-title, .#{$prefix}-vertical-right > .#{$prefix}-submenu > .#{$prefix}-submenu-title,.#{$prefix}-inline-collapsed > .#{$prefix}-submenu > .#{$prefix}-submenu-title
{
  height: 45px;
  line-height: 45px;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0;

  a {
    padding: 5px;
  }
}

.#{$prefix}-inline-collapsed > .#{$prefix}-item:nth-child(5) a .anticon{
  position: relative;
  bottom: 4px;
}

.#{$prefix}-vertical > .#{$prefix}-submenu > .#{$prefix}-submenu-title, .#{$prefix}-vertical-left > .#{$prefix}-submenu > .#{$prefix}-submenu-title, .#{$prefix}-vertical-right > .#{$prefix}-submenu > .#{$prefix}-submenu-title,.#{$prefix}-inline-collapsed > .#{$prefix}-submenu > .#{$prefix}-submenu-title {
  padding: 5px;
}

.#{$prefix}-submenu > .#{$prefix} {
  color: $textColor;
  background: rgba(255, 255, 255, 0.8);
  width: 70%;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  padding-bottom: 8px;
  padding-top: 4px;
  border-radius: 0 0 12px 12px;

  @media (max-width: 992px) {
    border-radius: 0 12px 12px 0;
    left: -4px;
    transform: none;
    padding-bottom: 0;
    padding-top: 0;
  }

  .#{$prefix}-item {
    background: transparent;
    padding: 0 8px !important;
    box-shadow: none;
  }

  .#{$prefix}-item-selected {
    background: none !important;

    a {
      color: $primaryColor !important;
    }
  }
}

#{$menuBorderRight} {
  border-color: $primaryColor;
}

.#{$prefix}-item-selected {
  color: $primaryColor;
}

.#{$prefix}-submenu-inline > .#{$prefix}-submenu-title .#{$prefix}-submenu-arrow,
.#{$prefix}-submenu-selected > .#{$prefix}-submenu-title .#{$prefix}-submenu-arrow {
   &::before, &::after {
     background-image: linear-gradient(to right, $textColor, $textColor);
   }
 }
.#{$prefix}-submenu-open > .#{$prefix}-submenu-title .#{$prefix}-submenu-arrow,
.#{$prefix}-submenu > .#{$prefix}-submenu-title:hover .#{$prefix}-submenu-arrow,
{
  &::before, &::after {
    background-image: linear-gradient(to right, $primaryColor, $primaryColor);
  }
}


.#{$darkClass} {
  @extend %baseMenu;
  &.#{$prefix} {
    background: $componentBackground;
  }
  .#{$prefix}-inline.#{$prefix}-sub,
  &.#{$prefix}-sub {
    background: transparent;
  }
  .#{$prefix}-item:hover,
  .#{$prefix}-item-active,
  .#{$prefix}-submenu-active,
  .#{$prefix}-submenu-open,
  .#{$prefix}-submenu-selected,
  .#{$prefix}-submenu-title:hover {
    color: $primaryColor;
  }

  @include selectedItemStyle($darkClass);
}
