@import 'variables';

$prefix: $antPrefix + -layout;

.#{$prefix} {
  background: $bodyBackground;
  &-header,
  &-sider,
  &-sider-trigger {
    background: $clientColor;
  }
}

#root
  > section.#{$prefix}
  > aside.#{$prefix}-sider
  > div.#{$prefix}-sider-children {
  max-height: calc(100vh - 70px);
  height: calc(100vh - 70px);
  overflow: hidden;
}

// #root
//   > section.#{$prefix}
//   > section.#{$prefix}
//   > .#{$prefix}-content
//   > .ant-page-header
//   + div:last-child {
//   height: calc(100vh - 70px - 38px - 84px);
//   overflow: auto;
// }

// #root
//   > section.#{$prefix}
//   > section.#{$prefix}
//   > .#{$prefix}-content
//   > div:last-child {
//   height: calc(100vh - 70px - 38px);
//   overflow: auto;
// }

.fKLgwq:last-child {
  margin-right: 28px;
}
