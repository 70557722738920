@import 'variables';

$prefix: $antPrefix + -switch;

.#{$prefix} {
  border-color: $borderColor;
  //background-color: $contrastBackground;
  &-checked {
    background-color: $primaryColor;
  }
}
