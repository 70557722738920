@import 'variables';

$prefix: $antPrefix + -steps;

.#{$prefix} {
  color: $textColor;
  .#{$prefix}-item-title {
    color: $textColor;
    &::after {
      background: $contrastBackground;
    }
  }

  &-item-process > .#{$prefix}-item-container {
    & > .#{$prefix}-item-content {
      .#{$prefix}-item-title {
        color: $textColor;
        &::after {
          background-color: $contrastBackground;
        }
      }
      .#{$prefix}-item-subtitle {
        color: $textColor;
      }
    }
    & > .#{$prefix}-item-tail::after {
      background-color: $contrastBackground;
    }
  }

  &-item-wait {
    .#{$prefix}-item-icon {
      background-color: $contrastBackground;
      border-color: $disabledColor;
      & > .#{$prefix}-icon {
        color: $disabledColor;
      }
    }
    & > .#{$prefix}-item-container > .#{$prefix}-item-content {
      & > .#{$prefix}-item-title {
        color: $disabledColor;
        &::after {
          background-color: $contrastBackground;
        }
      }
      .#{$prefix}-item-subtitle {
        color: $textColor;
      }
    }
    & > .#{$prefix}-item-container .#{$prefix}-item-tail::after {
      background-color: $contrastBackground;
    }
  }
  &-item-finish {
    .#{$prefix}-item-icon {
      background-color: #fff;
    }
    & > .#{$prefix}-item-container > .#{$prefix}-item-content {
      & > .#{$prefix}-item-title,
      .#{$prefix}-item-subtitle {
        color: $primaryColor;
      }
    }
  }
}

.#{$prefix}-item-process .#{$prefix}-item-icon {
  background: $primaryColor;
  border-color: $primaryColor;
}

.#{$prefix}-item-finish .#{$prefix}-item-icon {
  border-color: $primaryColor;
}

.#{$prefix}-item-finish .#{$prefix}-item-icon > .#{$prefix}-icon {
  color: $primaryColor;
}

.#{$prefix}-item-finish
  > .#{$prefix}-item-container
  > .#{$prefix}-item-content
  > .#{$prefix}-item-title::after {
  background-color: $primaryColor;
}

.#{$prefix}-navigation .#{$prefix}-item::before {
  height: 3px !important;
  background: $primaryColor !important;
}
