@import 'variables';

$prefix: $antPrefix + -upload;

.#{$prefix}, .#{$antPrefix}-form-item  {
  color: $textColor;

  .#{$prefix}.#{$prefix}-drag {
    background: $componentBackground;
  }
}
