@import 'variables';

$prefix: $antPrefix + -btn;
$notClasses: '&:not(.#{$prefix}-primary):not(.#{$prefix}-add):not(.#{$prefix}-danger):not(.#{$prefix}-dangerous):not(.#{$prefix}-link):not(.#{$antPrefix}-dropdown-trigger):not(.#{$antPrefix}-input-search-button)';
$ghostClass: '.#{$prefix}-background-ghost';

%disabledStyle {
  background-color: $bodyBackground;
  color: $disabledColor;
  &:hover {
    background-color: $bodyBackground;
    color: $disabledColor;
  }
}

%primaryButton {
  &:not([disabled]) {
    &:not(#{$ghostClass}) {
      background-color: $primaryColor;
      &:hover {
        color: $primaryColor;
        background-color: transparent;
        text-shadow: none;
        .anticon {
          color: $primaryColor;
        }
      }
    }
    border-color: $primaryColor;
    &:hover {
      color: $primaryColor;
      background-color: transparent;
    }
    &#{$ghostClass} {
      color: $primaryColor;
      &:hover {
        color: $primaryHover;
      }
    }
  }
  &:disabled {
    border-color: $borderColor;
    &:hover {
      border-color: $disabledColor;
    }
    &:not(#{$ghostClass}) {
      @extend %disabledStyle;
    }
  }
}

%addButton {
  &:not([disabled]) {
    &:not(#{$ghostClass}) {
      color: #fff;
      background-color: $addColor;
      &:hover {
        color: $addColor;
        background-color: transparent;
        text-shadow: none;
      }
    }
    border-color: $addColor;
    &:hover {
      color: $addColor;
      background-color: transparent;
    }
    &#{$ghostClass} {
      color: $addColor;
      &:hover {
        color: $primaryHover;
      }
    }
  }
  &:disabled {
    border-color: $borderColor;
    &:hover {
      border-color: $disabledColor;
    }
    &:not(#{$ghostClass}) {
      @extend %disabledStyle;
    }
  }
}

%defaultButton {
  &:not([disabled]) {
    background-color: $componentBackground;
    color: $textColor;
    border-color: $borderColor;
    &:hover {
      color: $primaryColor;
      border-color: $primaryColor;
    }
  }
  &:disabled {
    color: $disabledColor;
    background-color: $bodyBackground;
    border-color: $borderColor;
  }
}

%linkButton {
  &:not(disabled) {
    color: $primaryColor;
    border: none;
    &:hover {
      color: $primaryColor;
      opacity: 0.8;
    }
    &#{$ghostClass}:hover {
      color: $itemHoverColor;
    }
  }
  &:disabled {
    color: $disabledColor;
    &:hover {
      color: $disabledColor;
    }
  }
}

%dangerButton {
  &:not(disabled) {
    background-color: $secondaryColor;
    color: $secondaryHover;
    border-color: $secondaryColor;
    &:hover,
    &:focus,
    &:active {
      background-color: transparent;
      color: $secondaryColor;
      border-color: $secondaryColor;
      opacity: 1;
    }
  }
  &:disabled {
    @extend %disabledStyle;
    border-color: $borderColor;
    &:hover {
      border-color: $borderColor;
    }
  }
  &.#{$prefix}-link:disabled {
    color: $disabledColor;
    &:hover {
      color: $disabledColor;
    }
  }
  &.#{$prefix}-background-ghost {
    border-color: $secondaryColor;
    color: $secondaryColor;
    &:hover,
    &:focus,
    &:active {
      border-color: $secondaryColor;
      color: $secondaryColor;
    }
  }
}

%ghostButton {
  &:not(disabled) {
    &.#{$prefix}-primary:hover,
    &.#{$prefix}-primary:focus {
      border-color: $primaryColor;
    }
  }
  &:disabled {
    color: $disabledColor;
    &:hover {
      color: $disabledColor;
    }
  }
}

.#{$prefix}-primary {
  @extend %primaryButton;
}
.#{$prefix}-add {
  @extend %addButton;
}
.#{$prefix}-link {
  @extend %linkButton;
}
.#{$prefix}-dangerous {
  @extend %dangerButton;
}
.#{$prefix}-background-ghost {
  @extend %ghostButton;
}
.#{$prefix} {
  &:not(.#{$prefix}-circle):not(.ant-input-search-button) {
    border-radius: 50px;
    padding: 5px 16px;
    height: 34px;
    line-height: auto;
  }
  &::before {
    background: $bodyBackground;
  }

  & > svg {
    transition: margin-left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  & > svg + span,
  & > span + svg {
    margin-left: 8px;
  }

  #{$notClasses} {
    @extend %defaultButton;
  }
  &-lg {
    font-size: 12px;
  }
  &-sm {
    line-height: 100%;
  }
}

.#{$prefix}-primary.#{$prefix}-dangerous:not([disabled]):not(.ant-btn-background-ghost),
.#{$prefix}-danger:not(.#{$prefix}-link):not([disabled]) {
  background: $secondaryColor;
  border-color: $secondaryColor;
  &:hover {
    background: #fff;
    color: $secondaryColor;
  }
}

.#{$prefix}.#{$prefix}-icon-only.#{$antPrefix}-input-search-button {
  color: #fff !important;
  background: $primaryColor;
  border-radius: 5px !important;
  border-color: $primaryColor !important;
  transition: color 0.3s;
  &:hover {
    color: $primaryColor !important;
    background: #fff;
  }
  .anticon-search {
    vertical-align: -0.18em;
  }
}
