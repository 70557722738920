.orgchart-container {
	border: none;
}

.orgchart-container .orgchart {
	background-image: none;
}

.orgchart-container::-webkit-scrollbar {
	height: 4px;
}

.orgchart ul li .oc-node .oc-heading .oc-symbol {
	display: none;
}

.orgchart ul li .oc-node .oc-heading {
	font-size: 0.8rem;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 40px !important;
	width: 145px;
	border-radius: 0;
	background-color: unset !important;
	color: #474c58;
	box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.2);
}

.orgchart ul li .oc-node .oc-content {
	font-size: 0.8rem;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 40px !important;
	width: 145px;
	border-top: none;
	border-left: none;
	border-bottom: none;
	border-right: none;
	border-radius: 0;
	box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.2);
}

.orgchart ul li .oc-node:hover {
	background-color: unset !important;
}

.orgchart ul li .oc-node:not(:only-child)::after,
.orgchart > ul > li > ul li::before,
.orgchart > ul > li > ul li > .oc-node::before {
	background-color: #ffd591;
	border-top: 2px solid #ffd591;
}
