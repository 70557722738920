.actionCommentContainer {
  margin-top: 1rem;
}

.listContainer .ant-list-items {
  max-height: 322px;
  overflow: hidden;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 3px;
  }
}

.listContainer .ant-comment {
  margin: 1rem;
  padding-left: 1rem;
}

.formHistory .ant-form-item-control-input-content {
  max-width: 97% !important;
}
