/*:root { --boxShadowTable: }
.comparisonTable .choosed {
	position: relative;
}
.comparisonTable .choosed:before {
	box-shadow: var(--boxShadowTable);
    content: " ";
    height: 100%;
    top: 0;
    left: -15px;
    position: absolute;
    width: 15px;
}

.comparisonTable .choosed:after {
	box-shadow: var(--boxShadowTable);
    content: " ";
    height: 100%;
    top: 0;
    right: -15px;
    position: absolute;
    width: 15px;
}*/

.comparisonTable tbody tr td:nth-child(2), .comparisonTable tbody tr td:nth-child(3) {
	font-weight: bold;
} 

.comparisonTable ul {
	margin: auto;
    padding: 0;
}

.table_contracts .ant-table-filter-column {
    margin: -11px -16px;
}

.table_sellers .ant-table-filter-column {
    margin: -11px 0px;
    height: 40px;
}

.table_contracts::-webkit-scrollbar {
    height: 3px
}

.table_contracts .ant-table-filter-column .ant-table-filter-trigger-container .ant-table-filter-trigger .anticon {
    margin-left: 12px;  
}

.comparisonTableBenefit .ant-table.ant-table-bordered > .ant-table-container {
    border: none !important
}

.comparisonTableBenefit .ant-table .ant-table-thead > tr > th {
    background: none !important
}

/* .table-row-light > td {
    background-color: #e6f7ff52 !important;
} */

.comparisonTableBenefit .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > td, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > td, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > td, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > td {
    border-right: none !important;
}

.comparisonTableBenefit .editable-row {
    cursor: pointer;
}

.comparisonTableBenefit .editable-row:hover > td {
    background: #fffffd !important;
}

.ant-btn-primary:disabled:not(.ant-btn-background-ghost), .ant-btn-add:disabled:not(.ant-btn-background-ghost), .ant-btn-dangerous:disabled, .ant-input-affix-wrapper-disabled, .ant-input[disabled] {
    box-shadow: none !important;
}
