@import 'variables';

$prefix: $antPrefix + -pagination;
$disabledClasses: '.#{$prefix}-disabled a, .#{$prefix}-disabled:hover a, .#{$prefix}-disabled:focus a, .#{$prefix}-disabled .#{$prefix}-item-link, .#{$prefix}-disabled:hover .#{$prefix}-item-link, .#{$prefix}-disabled:focus .#{$prefix}-item-link';

.#{$prefix} {
  color: $textColor;
  &-prev,
  &-next,
  &-jump-prev,
  &-jump-next,
  &-item {
    .#{$prefix}-item-link {
      background-color: transparent;
    }
    &:not(.#{$prefix}-disabled) {
      color: $textColor;
      background-color: transparent;
      border-color: $borderColor;
      &:hover {
        border-color: $primaryColor;
        a {
          border-color: $primaryColor;
        }
      }
      .#{$prefix}-item-link,
      a {
        border-color: $borderColor;
        color: $textColor;
      }
    }
    &-container,
    .#{$prefix}-item-container {
      .#{$prefix}-item-ellipsis {
        color: $disabledColor;
        a {
          color: $disabledColor;
        }
      }
      .#{$prefix}-item-link-icon {
        color: $primaryColor;
      }
    }
  }
  &-item {
    &-active {
      border-color: $primaryColor;
      a {
        color: $primaryColor;
      }
    }
  }
}
#{$disabledClasses} {
  color: $disabledColor;
  border-color: $borderColor;
}

.#{$prefix}-prev:not(.#{$prefix}-disabled),
.#{$prefix}-next:not(.#{$prefix}-disabled),
.#{$prefix}-jump-prev:not(.#{$prefix}-disabled),
.#{$prefix}-jump-next:not(.#{$prefix}-disabled),
.#{$prefix}-item:not(.#{$prefix}-disabled),
.#{$prefix}-disabled a,
.#{$prefix}-disabled:hover a,
.#{$prefix}-disabled:focus a,
.#{$prefix}-disabled .#{$prefix}-item-link,
.#{$prefix}-disabled:hover .#{$prefix}-item-link,
.#{$prefix}-disabled:focus .#{$prefix}-item-link,
.#{$prefix}-prev:not(.#{$prefix}-disabled) .#{$prefix}-item-link,
.#{$prefix}-prev:not(.#{$prefix}-disabled) a,
.#{$prefix}-next:not(.#{$prefix}-disabled) .#{$prefix}-item-link,
.#{$prefix}-next:not(.#{$prefix}-disabled) a,
.#{$prefix}-jump-prev:not(.#{$prefix}-disabled) .#{$prefix}-item-link,
.#{$prefix}-jump-prev:not(.#{$prefix}-disabled) a,
.#{$prefix}-jump-next:not(.#{$prefix}-disabled) .#{$prefix}-item-link,
.#{$prefix}-jump-next:not(.#{$prefix}-disabled) a,
.#{$prefix}-item:not(.#{$prefix}-disabled) .#{$prefix}-item-link,
.#{$prefix}-item:not(.#{$prefix}-disabled) a {
  border: none;
}

.#{$prefix}-prev:not(.#{$prefix}-disabled),
.#{$prefix}-next:not(.#{$prefix}-disabled),
.#{$prefix}-jump-prev:not(.#{$prefix}-disabled),
.#{$prefix}-jump-next:not(.#{$prefix}-disabled),
.#{$prefix}-item:not(.#{$prefix}-disabled),
.#{$prefix}-prev:not(.#{$prefix}-disabled) .#{$prefix}-item-link,
.#{$prefix}-prev:not(.#{$prefix}-disabled) a,
.#{$prefix}-next:not(.#{$prefix}-disabled) .#{$prefix}-item-link,
.#{$prefix}-next:not(.#{$prefix}-disabled) a,
.#{$prefix}-jump-prev:not(.#{$prefix}-disabled) .#{$prefix}-item-link,
.#{$prefix}-jump-prev:not(.#{$prefix}-disabled) a,
.#{$prefix}-jump-next:not(.#{$prefix}-disabled) .#{$prefix}-item-link,
.#{$prefix}-jump-next:not(.#{$prefix}-disabled) a,
.#{$prefix}-item:not(.#{$prefix}-disabled) .#{$prefix}-item-link,
.#{$prefix}-item:not(.#{$prefix}-disabled) a {
  &:hover {
    color: $primaryColor;
  }
}

.#{$prefix}-item {
  font-size: 16px;
}

.#{$prefix}-item-active {
  font-weight: 700;
  font-size: 18px;
}

.#{$prefix}-options-size-changer.ant-select.ant-select-single:not(.ant-select-customize-input):not(.ant-select-disabled),
.#{$prefix}-options-size-changer.ant-select.ant-select-single:not(.ant-select-customize-input).ant-select-focused:not(.ant-select-disabled),
.#{$prefix}-options-size-changer.ant-select.ant-select-single:not(.ant-select-customize-input).ant-select-open:not(.ant-select-disabled) {
  .#{$antPrefix}-select-selector {
    border: none;
    box-shadow: none;
    background: transparent;
    background-color: transparent;
  }
}
