@import 'variables';

$prefix: $antPrefix + -divider;

.#{$prefix} {
  &-horizontal {
    &.#{$prefix} {
      &-with-text-center,
      &-with-text-left,
      &-with-text-right {
        color: $textColor;
        &::before,
        &::after {
          border-color: $textColor;
        }
      }
    }
  }
}

.#{$prefix}-inner-text {
  font-weight: 600;
  padding: 0 8px;
}